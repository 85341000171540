<template>
  <div id="hallindex">
    <header>
      <div class="left">
        <img src="../../img/板块图标 - 副本_slices/logo.png" alt="" />
      </div>
      <ul class="right">
        <li @click="backto()">
          <img src="../../img/上课/返回.png" alt="" />
          <span>返回</span>
        </li>
        <li>
          <el-dropdown
            trigger="click"
            placement="bottom-start"
            style="height: 50/96in; display: flex"
          >
            <div
              class="el-dropdown-link"
              style="display: flex; align-items: center; height: 30px"
            >
              <img src="../../img/上课/帮助 (5).png" alt="" />
              帮助
            </div>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>
                <span @click="tohelp">系统通知</span>
              </el-dropdown-item> -->
              <el-dropdown-item>
                <span @click="tohelp2">系统介绍</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li @click="toset">
          <img src="../../img/上课/设置 (6).png" alt="" />
          <span>设置</span>
        </li>
        <li @click="out">
          <img src="../../img/上课/结束.png" alt="" />
          <span>结束课程</span>
        </li>
      </ul>
    </header>
    <main>
      <div class="leftList" v-show="btnfull == 0">
        <ul class="info">
          <li>
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item
                :name="index"
                v-for="(item, index) in list"
                :key="index"
              >
                <template slot="title">
                  <img
                    v-show="activeName == index"
                    class="img-title"
                    src="../../img/上课/文件夹-开_folder-open (1).png"
                    alt=""
                  />
                  <img
                    v-show="activeName != index"
                    class="img-title"
                    src="../../img/上课/文件夹-关_folder-close (2) 拷贝 3.png"
                    alt=""
                  />
                  <span>{{ item.catalog_name }}</span>
                </template>
                <ul class="list">
                  <li
                    v-for="(item1, index1) in item.catalog"
                    :key="index1"
                    @click="
                      listbg = index1;
                      getcid(item1.id);
                    "
                    :class="{ listbg: activeName == index && listbg == index1 }"
                  >
                    <div class="img">
                      <img
                        v-if="listbg == index1"
                        src="../../img/上课/三角下标（正方形） (1).png"
                        alt=""
                      />
                    </div>

                    <span>{{ item1.catalog_name }}</span>
                  </li>
                </ul>
              </el-collapse-item>
            </el-collapse>
          </li>
        </ul>
      </div>
      <div class="rightLists">
        <ul>
          <li
            v-for="(item, index) in rightList"
            :key="index"
            @click="hall(item.id, item.file_type)"
          >
            <img
              v-if="item.file_type == 1"
              src="../../img/板块图标 - 副本_slices/ppt 拷贝.png"
              alt=""
            />
            <img
              v-else-if="item.file_type == 2"
              src="../../img/板块图标 - 副本_slices/word (3) 拷贝 2.png"
              alt=""
            />
            <img
              v-else-if="item.file_type == 3"
              src="../../img/板块图标 - 副本_slices/视频 (2) 拷贝 2.png"
              alt=""
            />
            <img
              v-else-if="item.file_type == 4"
              src="../../img/板块图标 - 副本_slices/音频 (1) 拷贝 2.png"
              alt=""
            />
            <img
              v-else-if="item.file_type == 5"
              src="../../img/板块图标 - 副本_slices/图片 (3) 拷贝 2.png"
              alt=""
            />
            <img
              v-else-if="item.file_type == 6"
              src="../../img/板块图标 - 副本_slices/？ 拷贝 2.png"
              alt=""
            />
            <img
              v-else-if="item.file_type == 7"
              src="../../img/板块图标 - 副本_slices/三维分析 拷贝 2.png"
              alt=""
            />
            <img
              v-else-if="item.file_type == 8"
              src="../../img/板块图标 - 副本_slices/椭圆 2 拷贝 2.png"
              alt=""
            />
            <img
              v-else-if="item.file_type == 9"
              src="../../img/板块图标 - 副本_slices/360OnlinVote (1) 拷贝 2.png"
              alt=""
            />
            <div class="filename" v-if="item.file_name">
              {{ item.file_name }}
            </div>
            <div class="filename" v-else>
              <span v-if="item.file_type == 1">PPT资源</span>
              <span v-else-if="item.file_type == 2">文档资源</span>
              <span v-else-if="item.file_type == 3">视频资源</span>
              <span v-else-if="item.file_type == 4">音频资源</span>
              <span v-else-if="item.file_type == 5">图文资源</span>
              <span v-else-if="item.file_type == 6">问卷资源</span>
              <span v-else-if="item.file_type == 7">模型资源</span>
              <span v-else-if="item.file_type == 8">试题资源</span>
              <span v-else>实景资源</span>
            </div>
          </li>
        </ul>
      </div>
    </main>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {
      activeName: "0",
      btnfull: 0,
      listbg: "",
      list: "",
      rightList: "",
    };
  },
  mounted() {
    this.getinfo();
  },
  methods: {
    getinfo() {
      util.post("/catalog", { csid: this.$route.query.csid }, (res) => {
        console.log(res.data);
        this.list = res.data;
        this.csid = this.$route.query.csid;
        this.getcid(res.data[0].catalog[0].id);
      });
    },
    getcid(id) {
      util.post("/catalogFile", { catalog_id: id }, (res) => {
        console.log(res.data);
        this.rightList = res.data;
        // this.mine(this.rightList[0].file_type, this.rightList[0].id);
        // this.myThings("ppt", res.data[0].id);
        // this.pptlist = res.data.filter((item) => item.file_type == "1");
        // this.wenlist = res.data.filter((item) => item.file_type == "2");
        // this.videolist = res.data.filter((item) => item.file_type == "3");
        // this.mp3list = res.data.filter((item) => item.file_type == "4");
        // this.picturelist = res.data.filter((item) => item.file_type == "5");
        // this.questionlist = res.data.filter((item) => item.file_type == "6");
        // this.modellist = res.data.filter((item) => item.file_type == "7");
        // this.topiclist = res.data.filter((item) => item.file_type == "8");
        // this.reallist = res.data.filter((item) => item.file_type == "9");
      });
    },
    hall(id, filetype) {
      console.log(id);
      this.$router.push({
        path: "/hall",
        query: {
          dataid: id,
          file_type: filetype,
          csid: this.$route.query.csid,
        },
      });
    },
    toset() {
      this.$router.push("/set");
    },
    out() {
      util.post(
        "/courseEnd",
        {
          md_str: localStorage.getItem("md"),
        },
        (res) => {
          if (res.code == 1) {
            this.$router.push("/main/starts");
          }
        }
      );
    },
    tohelp() {
      this.$router.push({
        path: "/help",
        query: {
          id: 1,
        },
      });
    },
    tohelp2() {
      this.$router.push({
        path: "/help",
        query: {
          id: 2,
        },
      });
    },
    backto() {
      this.$router.push("/main/starts");
    },
  },
};
</script>
<style lang="less" scoped>
#hallindex {
  min-height: 920/96in;
  height: 100vh;
  header {
    height: 60/96in;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.35rem /* 35/100 */ 0 0.2rem /* 56/100 */;
    border-bottom: 1px solid #cdcdcd;
    .left {
      display: flex;
      img {
        height: 36/96in;
      }
    }
    .right {
      display: flex;
      li {
        margin-left: 0.33rem /* 33/100 */;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          height: 22/96in;
          margin-right: 7px;
        }
      }
    }
  }
  main {
    display: flex;
    justify-content: flex-start;
    height: calc(100% - 0.625in);
    background-color: #eff0f2;
  }
  .leftList {
    z-index: 1;
    width: 160/96in;
    background-color: #fff;
    // transform: translateX(-100%);
    // border-right: 1px solid #cdcdcd;
    border-right: 1px solid #cdcdcd;
    .info {
      width: 160/96in;
      > li {
        padding-top: 47px;
        .img-title {
          margin-left: 22px;
          margin-right: 14px;
        }
        span {
          font-size: 16px;
        }
      }
      /deep/.el-collapse {
        border-bottom: none;
        border-top: none;
        width: 159/96in !important;
      }
      /deep/.el-collapse-item__header,
      /deep/.el-collapse-item__wrap {
        border-bottom: none;
      }
      /deep/.el-collapse-item__content {
        padding-bottom: 0;
      }
      .list {
        background-color: rgba(204, 230, 255, 0.18);
        li {
          display: flex;
          height: 32px;
          line-height: 32px;
          color: #000;
          font-size: 500;
          cursor: pointer;
          span {
            font-size: 14px;
          }
          .img {
            width: 8px;
            margin-left: 33px;
            margin-right: 11px;
            img {
              width: 100%;
            }
          }
        }
        .listbg {
          // background: url("../../img/上课/矩形 3 拷贝 2.png");
          background-image: linear-gradient(
            to right,
            #1c5eff,
            rgb(62, 173, 247)
          );
          color: #fff;
        }
      }
    }
  }
  .rightLists {
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 71px;
        cursor: pointer;
        img {
          height: 80px;
        }
        .filename {
          height: 14/96in;
        }
      }
    }
  }
}
</style>